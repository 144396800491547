/* Let's highlight canvas boundaries */
#gjs {
  border: 4px solid #444;
  height: 90vh !important;
}

/* Reset some default styling */
.gjs-cv-canvas {
  top: 10;
  width: 100%;
  height: 100%;
}
.gjs-two-color {
  color: white !important;
}

.gjs-one-bg {
  background-color: #153b62 !important;
}

.gjs-pn-views-container {
  width: 20%;
  height: auto;
}

.gjs-pn-views {
  width: 25%;
}
.gjs-pn-options {
  right: 40%;
}
.gjs-block {
  width: auto;
  height: auto;
  min-height: auto;
}
.gjs-block {
  width: auto;
  height: auto;
  min-height: auto;
}
.panel__top {
  padding: 0;
  width: 100%;
  display: flex;
  position: initial;
  justify-content: center;
  justify-content: space-between;
}
.panel__basic-actions {
  position: initial;
}
.editor-row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  height: 300px;
}

.editor-canvas {
  flex-grow: 1;
}

.panel__right {
  flex-basis: 230px;
  position: relative;
  overflow-y: auto;
  background-color: aqua !important;
}
.panel__switcher {
  position: initial;
}
.panel__devices {
  position: initial;
}
.fa-desktop {
  content: url('./icons/screen.svg');
}
.fa-tablet {
  content: url('./icons/tab.svg');
}
.fa-mobile {
  content: url('./icons/smartphone.svg');
}

.change-theme-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 5px;
}

.change-theme-button:focus {
  /* background-color: yellow; */
  outline: none;
  box-shadow: 0 0 0 2pt #c5c5c575;
}
