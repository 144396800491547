$pure-white: #ffffff;
$almost-white: #f9f9f9;
$near-white: #f7f7f7;
$light-grey: #d4d4d4;
$primary-blue: #153b62;
$light-blue: #15648a;
$primary-orange: #f06024;
$deeper-orange: #fd5c18;
$light-orange: #f9711c;
$near-black: #191919;
$alert-red: rgb(228, 83, 39);
$dark-grey: #363636;
$secondary-black: #201a16;

.light-grey {
  color: $light-grey;
}

.near-black {
  color: $near-black;
}

.pure-white {
  color: $pure-white;
}

.almost-white {
  color: $almost-white;
}

.primary-blue {
  color: $primary-blue !important;
}

.light-blue {
  color: $light-blue !important;
}

.primary-orange {
  color: $primary-orange !important;
}

.bg-primary-blue {
  background-color: $primary-blue !important;
}

.bg-primary-orange {
  background-color: $primary-orange !important;
}

.bg-near-black {
  background-color: $near-black !important;
}

.bg-alert-red {
  background-color: $alert-red !important;
}

.bg-light-grey {
  background-color: $near-white;
}

.bg-pure-white {
  background-color: $pure-white;
}

.bg-dark-grey {
  background-color: $dark-grey;
}

.bg-secondary-black {
  background-color: $secondary-black;
}

.bg-deeper-orange {
  background-color: $deeper-orange;
}

.bg-light-blue {
  background-color: $light-blue;
}

.bg-light-orange {
  background-color: $light-orange;
}
